.card-wrapper {
  width: calc(100vw - 40px);
  min-height: 104px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 40px;
  gap: 8px;
}

.card-time {
  width: 120px;
  height: 80px;
  flex: none;
  align-items: flex-start;
  text-align: left;
}

hr {
  width: 100%;
  border: 1px solid var(--blue-2925-c);
  margin: 8px 0;
}
