.action-button {
  min-width: 180px;
  height: 54px;
  border-style: solid;
  border-radius: 5px;
  border-width: 1.5px;
}

.primary-button {
  background-color: var(--blue-2925-c);
  border-color: var(--blue-2925-c);
  color: #18181a;
}

.primary-button:active {
  background-color: rgba(255, 216, 98, 0.2);
}

.secondary-button {
  background-color: #5f5f5000;
  border-color: #f5f5f5;
  color: #f5f5f5;
}

.secondary-button:active {
  color: rgba(188, 188, 188, 0.5);
  border-color: rgba(188, 188, 188, 0.5);
}
