.time-picker-button-number {
  min-width: 80px;
  padding-left: 14.4px;
  padding-right: 14.4px;
}

.time-picker-button-max {
  min-width: 103px;
  padding-left: 18.54px;
  padding-right: 18.54px;
}

.time-picker-button-number,
.time-picker-button-max {
  height: 80px;
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
}

.label {
  justify-content: center;
}
