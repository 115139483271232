.modal {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 668px;
  height: 352px;
  background-color: #303030;
  border: solid 1px #000000;
  border-radius: 20px;
}

.modal-text-style {
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.modal-header {
  font-size: 28px;
  width: 612px;
  height: 40px;
  color: #f5f5f5;
  margin: 40px 28px 40px 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.header-question {
  display: flex;
  justify-content: center;
  width: 520px;
  height: 40px;
  margin-left: 40px;
}

.header-cancel-button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  margin: 40px 28px 40px 28px;
  gap: 16px;
  height: 192px;
  width: 612px;
}

.cta-button-primary {
  margin-left: 0;
  color: #18181a;
  background-color: #f5f5f5;
}

.cta-button-secondary {
  margin-left: 0;
  color: #ff0000;
  background-color: #bcbcbc;
}

.cta-button-primary,
.cta-button-secondary {
  font-size: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  opacity: 0.75;
  width: 612px;
  height: 88px;
  border-radius: 12px;
}
