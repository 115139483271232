.time-period-text {
  color: #ffffff;
  display: flex;
  align-self: start;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 120px;
}

.time-period-text-opacity {
  opacity: 20%;
}
