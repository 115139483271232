.floor-wrapper {
  position: relative;
  width: 100vw;
  height: 75vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  justify-self: center;
}
