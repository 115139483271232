:root {
  --jblue: #040066;
  --blue-072-c: #040AB2;
  --blue-285-c: #1941FA;
  --blue-2925-c: #3C82FF;

  --jred: #F00A0A;
  --red-1785-c: #FF3F3F;
  --red-184-c: #F16A6A;
  --red-197-c: #FF8982;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
