.wrapper {
  background-color: white;
  width: 100vw;
  height: 90px;
  border: 0;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 36px;
}

.wrapper-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time {
  margin-right: 20px;
}

.logo {
  width: 160px;
  height: 42px;
  margin-left: 40px;
  display: flex;
  align-items: center;
  transform: translateY(6px);
}
