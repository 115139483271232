.page-wrapper {
  position: absolute;
  width: 100vw;
  height: 678px;
  padding: 0;
  top: 90px;
  overflow-y: scroll;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0;
  gap: 35px;
}
